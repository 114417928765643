<template>
    <form-builder>
        <template>
            <div class="distribute">
                <v-row>
                    <v-col sm="4">
                        <h6 class="text-h6 mb-4">Share Voiceform</h6>
                        <public-link></public-link>
                        <v-card outlined class="pa-4 mt-5">
                            <v-icon size="32" class="mb-4">mdi-at</v-icon>
                            <div class="text-subtitle-1 mb-2">Send an email</div>
                            <div class="text-body-1 mb-4">Sharing your voiceform in an email allows recipients to launch
                                your voiceform right from their inbox.</div>
                            <share-email></share-email>
                        </v-card>
                    </v-col>
                    <v-col sm="8">
                        <h6 class="text-md-h6 mb-4">Embed in a web page</h6>
                        <v-row no-gutters>
                            <v-col md="4" v-for="widget in widgetTypes" :key="widget.type">
                                <v-card :outlined="true" :ripple="false" @click="handleWidgetSelect(widget.type)"
                                    :color="widgetType === widget.type ? 'primary lighten-5' : ''" min-height="180"
                                    class="pa-4 mr-4 mb-4" :disabled="isDisabled(widget.type)">
                                    <component :is="widget.type" />
                                    <div class="mt-2 text-subtitle-2">
                                        {{ widget.title }}
                                        <v-chip class="ml-1" color="deepPurple accent-1" dark v-if="widget.label" small label>{{ widget.label }}</v-chip>
                                    </div>
                                    <div class="mt-2 text-body-2">{{ widget.description }}</div>
                                </v-card>
                            </v-col>
                        </v-row>
                        <panel></panel>
                        <v-dialog v-model="widgetDialog" width="1230">
                            <widget-panel :widgetType="widgetType"></widget-panel>
                        </v-dialog>
                    </v-col>
                </v-row>
            </div>
        </template>
    </form-builder>
</template>
<script>
import ShareEmail from "./ShareEmail.vue";
import Popover from "@/components/icons/Popover.vue";
import Standard from "@/components/icons/Standard.vue";
import FullPage from "@/components/icons/FullPage.vue";
import Popup from "@/components/icons/Popup.vue";
import Slider from "@/components/icons/Slider.vue";
import SideTab from "@/components/icons/SideTab.vue";
import SingleQuestion from "@/components/icons/SingleQuestion.vue";
import { mapGetters } from "vuex";
import FormBuilder from '../../../layouts/FormBuilder.vue';
import WidgetPanel from "./widget/Index.vue";
import Panel from './Panel.vue';
import PublicLink from './PublicLink.vue';

export default {
    name: "Distribute",
    components: {
        ShareEmail,
        Popover,
        Standard,
        FullPage,
        Popup,
        Slider,
        SideTab,
        FormBuilder,
        WidgetPanel,
        Panel,
        PublicLink,
        SingleQuestion,
    },
    data() {
        return {
            widgetDialog: false,
            widgetType: "",
            copiedLink: false,
            widgetTypes: [
                {
                    title: "Standard",
                    type: "standard",
                    description: "Make your voiceform a part of your website."
                },
                {
                    title: "Single Question",
                    type: "single-question",
                    description: "Embed a single question voiceform on your website or external survey.",
                    label: "New"
                },
                {
                    title: "Full-Page",
                    type: "full-page",
                    description: "Show full-page voiceform when your website loads."
                },
                {
                    title: "Popup",
                    type: "popup",
                    description: "Your voiceform popup in the center of the screen."
                },
                {
                    title: "Slider",
                    type: "slider",
                    description: "Your voiceform slides in from the side of the screen."
                },
                {
                    title: "Popover",
                    type: "popover",
                    description: "Open voiceform as a floating popover when someone hits the button."
                },
                {
                    title: "Side Tab",
                    type: "side-tab",
                    description: "Open voiceform as a floating side panel when someone hits the button."
                },
            ],
            shareChannels: [
                {
                    network: "email",
                    icon: 'mdi-email'
                },
                {
                    network: "twitter",
                    icon: 'mdi-twitter'
                },
                {
                    network: "facebook",
                    icon: 'mdi-facebook'
                },
                {
                    network: "whatsapp",
                    icon: 'mdi-whatsapp'
                },
            ]
        };
    },

    computed: {
        ...mapGetters({
            features: "auth/features"
        }),

        showEmbeddingOptions() {
            return !!this.features && this.features.all_embedding_options
        },
        shareData() {
            return {
                url: this.sharableLink,
                title: "We’d love your feedback.",
                description: `\nHave a moment to share your thoughts? We’d really appreciate if you filled out this voiceform.`,
            };
        },
    },
    methods: {
        handleWidgetSelect(type) {
            this.widgetDialog = true;
            this.widgetType = type
        },
        isDisabled(type) {
            if (this.showEmbeddingOptions) {
                return false
            }

            if (type === 'standard') {
                return false
            }
            return true
        }
    },
};
</script>

<style lang="scss" scoped>
.distribute {
    display: flex;
    justify-content: space-between;
}
</style>
