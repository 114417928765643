<template>
    <v-card outlined class="pa-4">
        <v-icon size="32" class="mb-4">mdi-link-variant</v-icon>
        <div class="text-caption mb-2">Use the link to share your voiceform</div>
        <div class="d-flex">
            <v-text-field :value="sharableLink" outlined readonly dense ref="linkInput" v-on:focus="handleLinkInputFocus">
            </v-text-field>
            <v-btn @click="copyLink" :color="copiedLink ? 'primary' : 'secondary'" class="ml-1" height="40" elevation="0">{{
                copiedLink ? 'Copied' : 'Copy' }}</v-btn>
        </div>
        <div class="d-flex">
            <ShareNetwork v-for="channel in shareChannels " :key="channel.network" :network="channel.network"
                :url="shareData.url" :title="shareData.title" :description="shareData.description">
                <v-btn icon>
                    <v-icon>{{ channel.icon }}</v-icon>
                </v-btn>
            </ShareNetwork>
        </div>
    </v-card>
</template>

<script>
export default {
    name: "ShareLink",
    data() {
        return {
            copiedLink: false,
            shareChannels: [
                {
                    network: "email",
                    icon: 'mdi-email'
                },
                {
                    network: "twitter",
                    icon: 'mdi-twitter'
                },
                {
                    network: "facebook",
                    icon: 'mdi-facebook'
                },
                {
                    network: "whatsapp",
                    icon: 'mdi-whatsapp'
                },
            ]
        }
    },
    inject: ['survey'],
    computed: {
        sharableLink() {
            let link = "";
            if (this.survey) {
                link = process.env.VUE_APP_APP_URL + `/to/${this.survey.hash}`;
            }

            return link;
        },
        shareData() {
            return {
                url: this.sharableLink,
                title: "We’d love your feedback.",
                description: `\nHave a moment to share your thoughts? We’d really appreciate if you filled out this voiceform.`,
            };
        },
    },
    methods: {
        async copyLink() {
            try {
                this.$refs.linkInput.focus();
                await navigator.clipboard.writeText(this.sharableLink)
                this.copiedLink = true
                setTimeout(() => {
                    this.copiedLink = false
                }, 4000);
            } catch (error) {
                console.error("Cannot copy link", error);
            }
        },
        handleLinkInputFocus(event) {
            event.target.select()
            navigator.clipboard.writeText(this.sharableLink)
        },
    }
}
</script>