<template>
    <v-card class="d-flex" style="height: 620px;">
        <!-- widget editor section -->
        <widget-editor @updatePreview="updatePreviewInfo" :defaultWidgetType="widgetType" style="flex-basis: 380px">
        </widget-editor>

        <!-- widget preview section -->
        <div class="flex-grow-1 d-flex flex-column px-6 preview-container">
            <div class="mx-auto my-4" style="text-align: center">
                <v-btn-toggle v-model="previewMode" mandatory @change="updatePreview">
                    <v-btn outlined color="primary" value="desktop">
                        <v-icon color="primary">mdi-monitor</v-icon>
                    </v-btn>
                    <v-btn outlined color="primary" value="mobile">
                        <v-icon color="primary">mdi-cellphone</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </div>

            <!-- preview widget -->
            <div class="flex-grow-1 mb-6">
                <iframe class="widget-frame rounded ma-auto" ref="widgetFrame" frameBorder="0"
                    :style="widthAlign"></iframe>
            </div>
        </div>
    </v-card>
</template>

<script>
import Skeleton from '../../../../utils/widgetSkeletonLoader';
import WidgetEditor from './WidgetEditor.vue';

export default {
    name: 'WidgetPanel',
    data() {
        return {
            previewMode: 'desktop',
            currentType: this.widgetType,
            htmlValue: ''
        };
    },
    props: {
        widgetType: {
            type: String,
            required: true
        }
    },
    components: {
        WidgetEditor
    },
    computed: {
        widthAlign() {
            return this.previewMode === 'desktop'
                ? {
                    'width': 'calc(100% / 0.7)',
                    'height': 'calc(100% / 0.7)',
                    'transform-origin': 'top left',
                    'transform': 'scale(0.7)'
                } : {
                    'width': 'calc(300px / 0.75)',
                    'height': 'calc(100% / 0.75)',
                    'transform-origin': 'center top',
                    'transform': 'scale(0.75)'
                };
        }
    },
    methods: {
        updatePreviewInfo(type, value) {
            this.currentType = type;
            this.htmlValue = value;
            this.updatePreview();
        },
        updatePreview() {
            const iframeSrc = Skeleton[`${this.currentType}-${this.previewMode}`].replace(Skeleton.placeHolder, this.htmlValue);
            const widgetFrameDocument = this.$refs.widgetFrame.contentWindow.document;
            widgetFrameDocument.open();
            widgetFrameDocument.write(iframeSrc);
            widgetFrameDocument.close();
        }
    }
};
</script>

<style scoped lang="scss">
.preview-container {
    background: url("../../../../assets/img/widget-background.png");
    background-color: #FAFAFA;
    background-size: 10px;

    .widget-frame {
        background-color: #FAFAFA;
        display: block;
        border: 1px solid var(--v-primary-base);
    }
}
</style>
