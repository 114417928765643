<template>
    <v-select :value="value" @input="val=>$emit('input', val)" @change="val=>$emit('change', val)" hide-details="auto" placeholder="Select question" dense outlined :loading="loading" :disabled="loading" :items="questions" item-value="id" :rules="[rules.required]">
        <template v-slot:selection="{ item }">
            <v-chip style="min-width: 50px;" class="mr-2" color="primary" label small>
                <v-icon left small>{{ questionIcons[item.type] }}</v-icon>
                {{ item.order+1 }}
            </v-chip>
            <span style="max-width: 200px;" class="text-caption elipsis-text">{{ item.text || titles[item.type] }}</span>
        </template>
        <template v-slot:item="{ item }">
            <v-chip style="min-width: 50px;" class="mr-2" color="primary" label small>
                <v-icon left small>{{ questionIcons[item.type] }}</v-icon>
                {{ item.order+1 }}
            </v-chip>
            <span style="max-width: 300px;" class="text-caption elipsis-text">{{ item.text || titles[item.type] }}</span>
        </template>
    </v-select>
</template>

<script>
import { questionIcons, titles } from '@/configs/questionTypes';
import BuilderApi from '@/api/BuilderApi'
export default {
    name: 'QuestionSelector',
    props: ['value'],
    data() {
        return {
            loading: false,
            questions: [],
            questionIcons,
            titles,
            rules: {
                required: v => !!v || 'Required'
            }
        }
    },
    methods: {
        async loadQuestions(){
            try {
                this.loading = true;
                const res = await BuilderApi.listQuestions(this.$route.params.surveyId);
                if(res.data){
                    this.questions = res.data;
                }
            } catch (error) {
                console.error('Error loading questions', error);
            }finally{
                this.loading = false;
            }
        }
    },
    mounted(){
        this.loadQuestions();
    }
}
</script>

<style lang="scss" scoped>
.elipsis-text{
    white-space: nowrap; /* Prevents the text from wrapping onto the next line */
    overflow: hidden; /* Hide the overflow */
    text-overflow: ellipsis; /* Display ellipsis when text overflows */
    width: 100%;
}
</style>