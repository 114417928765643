<template>
    <div>
        <div class="text-h6 ma-8">Embed Settings</div>
        <v-form class="px-8" ref="form" style="max-height: 458px; overflow-y: auto">
            <v-select v-model="currentType" :items="widgetTypes" @change="updatePreview"
                item-text="title" item-value="type" outlined :menu-props="{ offsetY: true }">
                <!-- customize each element in the list -->
                <template v-slot:item="{ item }">
                    <component :is="item.type" />
                    <div class="ml-6">{{ item.title }}</div>
                </template>
                <!-- customize selected element in the list -->
                <template v-slot:selection="{ item }">
                    <component :is="item.type" />
                    <div class="ml-6">{{ item.title }}</div>
                </template>
            </v-select>

            <div v-if="currentType === 'standard'" class="d-flex mb-6" style="align-items: center">
                <div class="text-body-1 flex-grow-1">Width</div>
                <v-text-field v-model="widgetConfig.width" @change="updatePreview" type="number"
                    style="max-width: 99px; margin-right: -1px" class="rounded-r-0" outlined dense hide-details></v-text-field>
                <v-select v-model="widgetConfig.widthUnit" @change="updatePreview" style="max-width: 77px" class="rounded-l-0"
                    :items="['%', 'px']" outlined dense hide-details :menu-props="{ offsetY: true }"></v-select>
            </div>
            
            <div v-if="currentType === 'standard'" class="d-flex mb-6" style="align-items: center">
                <div class="text-body-1 flex-grow-1">Height</div>
                <v-text-field v-model="widgetConfig.height" @change="updatePreview" type="number"
                    style="max-width: 99px; margin-right: -1px" class="rounded-r-0" outlined dense hide-details></v-text-field>
                <v-select v-model="widgetConfig.heightUnit" @change="updatePreview" style="max-width: 77px" class="rounded-l-0"
                    outlined dense hide-details :items="['%', 'px']" :menu-props="{ offsetY: true }"></v-select>
            </div>

            <div v-if="['popup', 'slider', 'popover', 'side-tab'].includes(currentType)">
                <div class="d-flex mb-6" style="align-items: center">
                    <div class="text-body-1 flex-grow-1">{{ currentType === 'side-tab' ? 'Tab' : 'Button' }} size</div>
                    <v-select v-model="widgetConfig.buttonSize" style="max-width: 130px" outlined hide-details
                        @change="updatePreview" :items="buttonSizes" item-text="title" item-value="type"
                        :menu-props="{ offsetY: true }" dense>
                    </v-select>
                </div>

                <div class="d-flex mb-6" style="align-items: center">
                    <div class="text-body-1 flex-grow-1">{{ currentType === 'side-tab' ? 'Tab' : 'Button' }} style</div>
                    <v-select v-model="widgetConfig.buttonStyle" style="max-width: 130px" outlined hide-details @change="updatePreview"
                        :items="buttonStyles" item-text="title" item-value="type" :menu-props="{ offsetY: true }" dense>
                    </v-select>
                </div>

                <div class="d-flex mb-6" style="align-items: center">
                    <div class="text-body-1 flex-grow-1">Button color</div>
                    <v-select v-model="widgetConfig.buttonColor" style="max-width: 80px" outlined hide-details item-text="title"
                        item-value="type" :menu-props="{ offsetY: true }" dense>
                        <template v-slot:no-data>
                            <v-color-picker v-model="tempButtonColor" hide-mode-switch mode="hexa">
                            </v-color-picker>
                        </template>

                        <template v-slot:prepend-inner>
                            <v-icon :color="widgetConfig.buttonColor">mdi-water</v-icon>
                        </template>
                    </v-select>
                </div>

                <div class="d-flex mb-4" style="align-items: center">
                    <div class="text-body-1 flex-grow-1">Icon</div>
                    <div v-if="!widgetConfig.iconURL">
                        <v-btn hide-details dense small elevation="0" :loading="uploadingIcon" @click="$refs.icon_uploader.click()">Add
                        </v-btn>
                        <input class="d-none" type="file" accept="image/png, image/jpeg, image/gif" ref="icon_uploader" name="icon_uploader"
                            @change="processIcon($event)" />
                    </div>
                    <template v-else>
                        <v-img :src="widgetConfig.iconURL" max-width="24" max-height="24">
                        </v-img>
                        <div :style="iconDisplayStyle">
                        </div>
                        <v-btn icon small @click="removeIcon">
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                    </template>
                </div>

                <div v-if="currentType !== 'side-tab'" class="d-flex mb-4" style="align-items: center">
                    <div class="text-body-1 flex-grow-1">Icon only</div>
                    <v-switch v-model="widgetConfig.iconOnly" @change="updatePreview" class="ma-0 pa-0" hide-details dense></v-switch>
                </div>

                <div v-if="!widgetConfig.iconOnly" class="d-flex mb-4" style="align-items: center">
                    <div class="text-body-1 flex-grow-1">{{ currentType === 'side-tab' ? 'Tab' : 'Button' }} label</div>
                    <v-text-field v-model="widgetConfig.buttonLabel" @change="updatePreview" outlined
                        hide-details dense counter maxlength="24" style="max-width: 170px">
                        <template v-slot:append>
                            <div class="custom-counter">
                                <span>{{ widgetConfig.buttonLabel.length }}/24</span>
                            </div>
                        </template>
                    </v-text-field>
                </div>
            </div>


            <div v-if="['popup', 'slider', 'popover'].includes(currentType) && !widgetConfig.iconOnly">
                <div class="d-flex mb-4" style="align-items: center">
                    <div class="text-body-1 flex-grow-1">Font size</div>
                    <v-slider v-model="widgetConfig.fontSize" @change="updatePreview" min="1" max="99"
                        hide-details></v-slider>
                    <div>{{ widgetConfig.fontSize }}px</div>
                </div>

                <div class="d-flex mb-4" style="align-items: center">
                    <div class="text-body-1 flex-grow-1">Rounding</div>
                    <v-slider v-model="widgetConfig.borderRadius" @change="updatePreview" min="0"
                        max="36" hide-details></v-slider>
                    <div>{{ widgetConfig.borderRadius }}px</div>
                </div>
            </div>

            <div class="mb-3" v-if="currentType === 'single-question'">
                <question-selector v-model="widgetConfig.questionId" @change="updatePreview"></question-selector>
                <v-checkbox  v-model="widgetConfig.hideQuestion" label="Hide Question Text" @change="updatePreview" hide-details dense></v-checkbox>
                <v-checkbox v-model="widgetConfig.hideNextButton" label="Hide Next Button"  @change="updatePreview" hide-details dense></v-checkbox>
            </div>
        </v-form>

        <div class="px-8 mb-8">
            <v-btn @click="displayCode" block elevation="0" color="primary">
                Get code
            </v-btn>

            <template v-if="['single-question', 'standard'].includes(currentType)">
                <v-btn class="mt-3" @click="displayIframeCode" block outlined color="primary">

                    Get iframe code
                </v-btn>
                <div class="text-caption text-center mt-1">Use iframe if script is not supported.</div>
            </template>
        </div>

        <v-dialog v-model="showCodeDialog" width="500">
            <v-card class="pa-6">
                <v-btn icon class="d-block ml-auto" @click="showCodeDialog = false">
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
                <div class="text-h5 mb-4">Get code</div>

                <v-alert type="info" text :icon="false">
                    <div style="font-family: monospace; word-break:break-word; white-space: pre-line; font-size: 14px;">
                        {{ widgetHTMLString }}
                    </div>
                </v-alert>

                <div class="d-flex flex-row-reverse">
                    <v-btn color="primary" elevation="0" @click="copyCode">Copy Code</v-btn>
                    <v-btn class="mr-4" outlined text @click="showCodeDialog = false">Cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showIframeCodeDialog" width="500">
            <v-card class="pa-6">
                <div class="d-flex">
                    <div class="text-h6 mb-4">Get iframe code</div>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showIframeCodeDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>

                <v-alert type="info" text :icon="false">
                    <div style="font-family: monospace; word-break:break-word; white-space: pre-line; font-size: 14px;">
                        {{ iframeCode }}
                    </div>
                </v-alert>

                <div class="d-flex flex-row-reverse">
                    <v-btn color="primary" elevation="0" @click="copyIframeCode">Copy Code</v-btn>
                    <v-btn class="mr-4" outlined text @click="showIframeCodeDialog = false">Cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import { uploadMediaFile } from "../../../../api/survey";
import Popover from "@/components/icons/Popover.vue";
import Standard from "@/components/icons/Standard.vue";
import FullPage from "@/components/icons/FullPage.vue";
import Popup from "@/components/icons/Popup.vue";
import Slider from "@/components/icons/Slider.vue";
import SideTab from "@/components/icons/SideTab.vue";
import SingleQuestion from "@/components/icons/SingleQuestion.vue";
import QuestionSelector from './QuestionSelector.vue';

export default {
    name: 'WidgetEditor',
    components: {
        Popover,
        Standard,
        FullPage,
        Popup,
        Slider,
        SideTab,
        SingleQuestion,
        QuestionSelector,
    },
    props: {
        defaultWidgetType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showCodeDialog: false,
            showIframeCodeDialog: false,
            uploadingIcon:  false,
            currentType: this.defaultWidgetType,
            tempButtonColor: '#0066FF',
            iframeCode: '',
            onCooldown: false,
            widgetTypes: [
                { title: 'Standard', type: 'standard' },
                { title: 'Single Question', type: 'single-question'},
                { title: 'Full-Page', type: 'full-page' },
                { title: 'Popup', type: 'popup' },
                { title: 'Slider', type: 'slider' },
                { title: 'Popover', type: 'popover' },
                { title: 'Side Tab', type: 'side-tab' },
            ],
            buttonSizes: [
                { title: 'Small', type: 'small' },
                { title: 'Medium', type: 'medium' },
                { title: 'Large', type: 'large' }
            ],
            buttonStyles: [
                { title: 'Filled', type: 'filled' },
                { title: 'Outlined', type: 'outlined' },
                { title: 'Text', type: 'text' }
            ],
            widgetConfig: {
                width: 100,
                widthUnit: '%',
                height: 600,
                heightUnit: 'px',
                buttonSize: 'medium',
                buttonStyle: 'filled',
                buttonColor: '#0066FF',
                iconURL: null,
                iconOnly: false,
                buttonLabel: 'Speak up',
                fontSize: 16,
                borderRadius: 4,
                hideQuestion: false,
                hideNextButton: false,
                questionId: null
            }
        };
    },
    inject: ['survey'],
    computed: {
        widgetHTMLString() {
            const widgetAttribute = this.generateCode();
            let htmlSource = `<!-- Include the Voiceform script in the <head> of your page, if not already included --> \n`;
            // eslint-disable-next-line no-useless-escape
            htmlSource += `<script src="${widgetAttribute["src"]}"><\/script> \n\n`;
            htmlSource += `<!-- Insert the Voiceform widget code within the <body> of your HTML document --> \n`;

            htmlSource += `<vf-widget \n`;
            
            for (let key in widgetAttribute) {
                if(key === "src") continue;
                htmlSource += ` ${key}="${widgetAttribute[key]}"`
            }
            htmlSource += ` />`;
            return htmlSource;
        },
        iconDisplayStyle() {
            return this.widgetConfig.iconURL
                ? { 'background-image': `url(${this.widgetConfig.iconURL})` }
                : {};
        }
    },
    watch: {
        defaultWidgetType(value) {
            this.currentType = value;
            this.$emit('updatePreview', this.currentType, this.widgetHTMLString);
        },
        tempButtonColor(value) {
            this.widgetConfig.buttonColor = value;
            if (!this.onCooldown) {
                this.onCooldown = true;
                this.updatePreview();
                setTimeout(() => {
                    this.onCooldown = false;
                }, 500);
            }
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),

        parsedStandardAttribute() {
            return {
                "data-vf-height": `${this.widgetConfig.height}${this.widgetConfig.heightUnit}`,
                "data-vf-width": `${this.widgetConfig.width}${this.widgetConfig.widthUnit}`
            };
        },
        parsedFullPageAttribute() {
            return {};
        },
        parsedPopOrSliderAttribute() {
            return {
                "data-vf-btn-label": this.widgetConfig.buttonLabel,
                "data-vf-btn-color": this.widgetConfig.buttonColor,
                "data-vf-btn-style": this.widgetConfig.buttonStyle,
                "data-vf-btn-size": this.widgetConfig.buttonSize,
                "data-vf-btn-border-radius": `${this.widgetConfig.borderRadius}px`,
                "data-vf-btn-fonts-size": `${this.widgetConfig.fontSize}px`,
                ...(this.widgetConfig.iconURL ? { "data-vf-btn-icon": this.widgetConfig.iconURL } : {}),
                "data-vf-btn-icon-only": this.widgetConfig.iconOnly
            };
        },
        parsedSideTabAttribute() {
            return {
                "data-vf-btn-label": this.widgetConfig.buttonLabel,
                "data-vf-btn-color": this.widgetConfig.buttonColor,
                "data-vf-btn-style": this.widgetConfig.buttonStyle,
                "data-vf-btn-size": this.widgetConfig.buttonSize,
                ...(this.widgetConfig.iconURL ? { "data-vf-btn-icon": this.widgetConfig.iconURL } : {})
            };
        },
        parseSingleQuestionAttribute() {
            return {
                "data-question-id": this.widgetConfig.questionId,
                "data-vf-hide-question": this.widgetConfig.hideQuestion,
                "data-vf-hide-next-button": this.widgetConfig.hideNextButton
            };
        },
        generateCode() {
            const attributeParserMap = {
                "standard": this.parsedStandardAttribute,
                "full-page": this.parsedFullPageAttribute,
                "popup": this.parsedPopOrSliderAttribute,
                "slider": this.parsedPopOrSliderAttribute,
                "popover": this.parsedPopOrSliderAttribute,
                "side-tab": this.parsedSideTabAttribute,
                "single-question": this.parseSingleQuestionAttribute
            };

            const parsedAttribute = attributeParserMap[this.currentType]();
            parsedAttribute["data-mode"] = this.currentType;
            parsedAttribute["data-hash"] = process.env.VUE_APP_APP_ENV === 'local' ? "nl28Pj8tQZQ0mKzR" : this.survey.hash;
            if(this.currentType === 'single-question') {
                parsedAttribute["data-question-id"] = process.env.VUE_APP_APP_ENV === 'local' ? '77' : this.widgetConfig.questionId;
            }
            parsedAttribute["src"] = process.env.VUE_APP_APP_ENV === 'production'
                ? "https://cdn.voiceform.com/widgets/next/widgets.umd.js"
                : "https://cdn.voiceform.com/widgets/staging/widgets.umd.js";

            return parsedAttribute;
        },
        generateIframeCode(){
            let urlBase = process.env.VUE_APP_APP_ENV === 'production' ? 'https://app.voiceform.com' : 'https://staging.voiceform.com';
            let hash = process.env.VUE_APP_APP_ENV === 'local' ? 'nl28Pj8tQZQ0mKzR' : this.survey.hash;
            let url = `${urlBase}/to/${hash}`;
            if(this.currentType === 'single-question'){
                let questionId = process.env.VUE_APP_APP_ENV === 'local' ? '77' : this.widgetConfig.questionId;
                url += `/questions/${questionId}`;
            }
            let queryString = `?v-widget=${this.currentType}`;

            if(this.currentType === 'single-question'){
                if(this.widgetConfig.hideQuestion){
                    queryString += `&v_hide_question=true`;
                }
                if(this.widgetConfig.hideNextButton){
                    queryString += `&v_hide_next_button=true`;
                }
            }


            return `<iframe style="border: none; width: ${this.widgetConfig.width}${this.widgetConfig.widthUnit}; height: ${this.widgetConfig.height}${this.widgetConfig.heightUnit};" src="${url+queryString}" allow="microphone; camera"></iframe>`;
        
        },
        updatePreview() {
            const valid = this.$refs.form.validate();
            if (!valid) {
                return;
            }
            this.$emit('updatePreview', this.currentType, this.widgetHTMLString);
        },
        async processIcon(event) {
            try {
                this.uploadingIcon = true;
                const iconFile = event.target.files[0];
                const response = await uploadMediaFile(iconFile, this.survey.hash);
                this.widgetConfig.iconURL = response.data.url;
                this.uploadingIcon = false;
                this.updatePreview();
            } catch (err) {
                this.uploadingIcon = false;
                if (err.response.status !== 200) {
                    this.showSnackBar({
                        color: "error",
                        timeout: 3000,
                        text: err.response.data.message || "Unable to upload icons for widget. Please try again later."
                    });
                }
            }
        },
        removeIcon() {
            // we dont actually remove the icon file from the backend storage since the size is insignificant
            this.widgetConfig.iconURL = null;
            this.updatePreview();
        },
        displayCode() {
            const valid = this.$refs.form.validate();
            if (!valid) {
                return;
            }
            this.showCodeDialog = true;
        },
        displayIframeCode() {
            const valid = this.$refs.form.validate();
            if (!valid) {
                return;
            }
            this.iframeCode = this.generateIframeCode();
            this.showIframeCodeDialog = true;
        },
        async copyCode() {
            try {
                await navigator.clipboard.writeText(this.widgetHTMLString);
            } catch (error) {
                console.error("Cannot copy token", error);
            }
            this.showSnackBar({
                text: "Widget HTML copied.",
                color: "success",
                timeout: 1000,
            });
        },
        async copyIframeCode() {
            try {
                await navigator.clipboard.writeText(this.iframeCode);
            } catch (error) {
                console.error("Cannot copy token", error);
            }
            this.showSnackBar({
                text: "Widget HTML copied.",
                color: "success",
                timeout: 1000,
            });
        }


    },
    mounted() {
        setTimeout(() => {
            this.$emit('updatePreview', this.currentType, this.widgetHTMLString);
        }, 0);
    }
};
</script>

<style scoped lang="scss">
.custom-counter {
    display: table;
    min-height: 24px;

    span {
        display: table-cell;
        vertical-align: middle;
        color: var(--v-grey-base);
    }
}
</style>
