const placeHolder = '{vf-place-holder}';
const style = `
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <style>
        body {
            font-family: 'Poppins';
            font-size: 16px;   
        }
    </style>
`;

function makeDiv(width = 200, height = 12, borderRadius = 4) {
    return `<div style="width: ${width}px; height: ${height}px; margin: 24px;
        border-radius: ${borderRadius}px; background: #000000; opacity: 0.08;">&nbsp;</div>`;
}

function makeRect(width = 100, height = 100, borderRadius = 50) {
    return `<div style="width: ${width}px; height: ${height}px; margin: 12px 12px 12px 24px;
        border-radius: ${borderRadius}px; background: #000000; opacity: 0.08;">&nbsp;</div>`;
}

export default {
    placeHolder,
    'standard-desktop': `
        <div style="margin: auto; width: 688px">
        ${makeDiv(150)}
        ${makeDiv(300)}
        ${makeDiv(500)}
        ${makeDiv(400)}
        <div style="margin-left: 24px">${placeHolder}</div>
        ${makeDiv(500)}
        ${makeDiv(600)}
        ${makeDiv(640)}
        </div>
    `,
    'standard-mobile': `
        ${makeDiv(50)}
        ${makeDiv(100)}
        ${makeDiv(250)}
        ${makeDiv(200)}
        ${placeHolder}
        ${makeDiv(250)}
        ${makeDiv(300)}
        ${makeDiv(320)}
    `,
    'single-question-desktop': `
        <div style="margin: auto; width: 688px">
        ${makeDiv(150)}
        ${makeDiv(300)}
        ${makeDiv(500)}
        ${makeDiv(400)}
        <div style="margin-left: 24px">${placeHolder}</div>
        ${makeDiv(500)}
        ${makeDiv(600)}
        ${makeDiv(640)}
        </div>
    `,
    'single-question-mobile': `
        ${makeDiv(50)}
        ${makeDiv(100)}
        ${makeDiv(250)}
        ${makeDiv(200)}
        ${placeHolder}
        ${makeDiv(250)}
        ${makeDiv(300)}
        ${makeDiv(320)}
    `,
    'full-page-desktop': `
        ${placeHolder}
    `,
    'full-page-mobile': `
        ${placeHolder}
    `,
    'popup-desktop': `
        ${style}
        <div style="display: flex; align-items: center">
            ${makeRect(70, 70, 35)}
            <div>
                ${makeDiv(250)}
                ${makeDiv(300)}
            </div>
        </div>
        ${makeDiv(550)}
        ${makeDiv(200)}
        ${makeDiv(300)}
        <div style="display: flex; align-items: center; justify-content: space-evenly">
            <div>
                ${makeRect(130, 30, 4)}
                ${makeDiv(300)}
                ${makeDiv(280)}
                ${makeDiv(290)}
                ${makeDiv(260)}
                ${makeDiv(270)}
            </div>
            <div>
                ${makeRect(230, 230, 4)}
            </div>
        </div>
        ${makeDiv(200)}
        <div style="display: flex; align-items: center">
            <div style="display: inline-block; margin-left: 24px">${placeHolder}</div>
            <span style="margin-left: 20px">👈 Click the button to open your popup</span>
        </div>
        ${makeDiv(300)}
        ${makeDiv(550)}
        ${makeRect(730, 20, 4)}
    `,
    'slider-desktop': `
        ${style}
        <div style="display: flex; align-items: center">
            ${makeRect(70, 70, 35)}
            <div>
                ${makeDiv(250)}
                ${makeDiv(300)}
            </div>
        </div>
        ${makeDiv(550)}
        ${makeDiv(200)}
        ${makeDiv(300)}
        <div style="display: flex; align-items: center; justify-content: space-evenly">
            <div>
                ${makeRect(130, 30, 4)}
                ${makeDiv(300)}
                ${makeDiv(280)}
                ${makeDiv(290)}
                ${makeDiv(260)}
                ${makeDiv(270)}
            </div>
            <div>
                ${makeRect(230, 230, 4)}
            </div>
        </div>
        ${makeDiv(200)}
        <div style="display: flex; align-items: center">
            <div style="display: inline-block; margin-left: 24px">${placeHolder}</div>
            <div style="margin-left: 20px">👈 Click the button to open your slider</div>
        </div>
        ${makeDiv(300)}
        ${makeDiv(550)}
        ${makeRect(730, 20, 4)}
    `,
    'popover-desktop': `
        ${style}
        <div style="display: flex; align-items: center">
            ${makeRect(70, 70, 35)}
            <div>
                ${makeDiv(250)}
                ${makeDiv(300)}
            </div>
        </div>
        ${makeDiv(550)}
        ${makeDiv(200)}
        ${makeDiv(300)}
        <div style="display: flex; align-items: center; justify-content: space-evenly">
            <div>
                ${makeRect(130, 30, 4)}
                ${makeDiv(300)}
                ${makeDiv(280)}
                ${makeDiv(290)}
                ${makeDiv(260)}
                ${makeDiv(270)}
            </div>
            <div>
                ${makeRect(230, 230, 4)}
            </div>
        </div>
        ${makeDiv(200)}
        ${makeDiv(300)}
        ${makeDiv(550)}
        ${makeRect(730, 20, 4)}
        ${placeHolder}
        <span style="position: absolute; bottom: 48px; right:180px">Hit the button to open your popover 👉</span>
    `,
    'side-tab-desktop': `
        ${style}
        <div style="display: flex; align-items: center">
            ${makeRect(70, 70, 35)}
            <div>
                ${makeDiv(250)}
                ${makeDiv(300)}
            </div>
        </div>
        ${makeDiv(550)}
        ${makeDiv(800)}
        ${makeDiv(460)}
        <div>
            ${makeRect(230, 130, 4)}
            ${makeDiv(300)}
            ${makeDiv(680)}
            ${makeDiv(390)}
            ${makeDiv(260)}
            ${makeDiv(770)}
        </div>
        ${makeDiv(550)}
        ${makeDiv(300)}
        ${makeDiv(550)}
        ${placeHolder}
        <span style="position: absolute; top: 50%; right: 80px">Hit the tab to open your tab 👉</span>
    `,
    'popup-mobile': `
        ${style}
        <div style="display: flex; align-items: center">
            ${makeRect(70, 70, 35)}
            <div>
                ${makeDiv(150)}
                ${makeDiv(190)}
            </div>
        </div>
        <div style="display: flex; align-items: center">
            ${makeRect(300, 170, 4)}
        </div>
        ${makeDiv(150)}
        ${makeDiv(240)}
        ${makeDiv(200)}
        ${makeRect(300, 60, 4)}
        ${makeDiv(270)}
        ${makeDiv(100)}
        <div style="margin-left: 24px">${placeHolder}</div>
        <div style="margin: 12px 0 0 24px">☝️ Click the button to open your popup</div>
    `,
    'slider-mobile': `
        ${style}
        <div style="display: flex; align-items: center">
            ${makeRect(70, 70, 35)}
            <div>
                ${makeDiv(150)}
                ${makeDiv(190)}
            </div>
        </div>
        <div style="display: flex; align-items: center">
            ${makeRect(300, 170, 4)}
        </div>
        ${makeDiv(150)}
        ${makeDiv(240)}
        ${makeDiv(200)}
        ${makeRect(300, 60, 4)}
        ${makeDiv(270)}
        ${makeDiv(100)}
        <div style="margin-left: 16px">${placeHolder}</div>
        <div style="margin: 12px 0 0 24px">☝️ Click the button to open your slider</div>
    `,
    'popover-mobile': `
        ${style}
        <div style="display: flex; align-items: center">
            ${makeRect(70, 70, 35)}
            <div>
                ${makeDiv(150)}
                ${makeDiv(190)}
            </div>
        </div>
        <div style="display: flex; align-items: center">
            ${makeRect(300, 170, 4)}
        </div>
        ${makeDiv(150)}
        ${makeRect(220, 44, 4)}
        ${makeDiv(200)}
        ${makeRect(300, 30, 4)}
        ${makeDiv(270)}
        ${makeDiv(100)}
        <div style="margin: 12px 0 0 24px">Hit the button to open your popover 👇</div>
        <div style="margin-left: 16px">${placeHolder}</div>
    `,
    'side-tab-mobile': `
        ${style}
        <div style="display: flex; align-items: center">
            ${makeRect(70, 70, 35)}
            <div>
                ${makeDiv(150)}
                ${makeDiv(190)}
            </div>
        </div>
        <div style="display: flex; align-items: center">
            ${makeRect(300, 70, 4)}
        </div>
        ${makeDiv(150)}
        ${makeDiv(90)}
        ${makeDiv(110)}
        ${makeDiv(140)}
        <div style="margin: 12px 0 0 24px">Hit the tab to open your tab 👉</div>
        ${makeRect(130, 30, 4)}
        ${makeDiv(290)}
        ${makeDiv(200)}
        ${makeRect(300, 60, 4)}
        ${makeDiv(270)}
        ${makeDiv(100)}
        <div style="margin-left: 16px">${placeHolder}</div>
    `
};
