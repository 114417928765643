<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.5 2.5H6.5C3.73858 2.5 1.5 4.73858 1.5 7.5V24.5C1.5 27.2614 3.73858 29.5 6.5 29.5H25.5C28.2614 29.5 30.5 27.2614 30.5 24.5V7.5C30.5 4.73858 28.2614 2.5 25.5 2.5Z" fill="white" stroke="#ECECEF"/>
<rect x="5" y="6" width="22" height="5" rx="2" fill="#0066FF"/>
<rect x="5" y="14" width="8" height="5" rx="2" fill="#0066FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 27C24.0899 27 27 24.0899 27 20.5C27 16.9101 24.0899 14 20.5 14C16.9101 14 14 16.9101 14 20.5C14 24.0899 16.9101 27 20.5 27ZM21.7147 20.0938C21.7147 20.7681 21.1744 21.3125 20.5 21.3125C19.8256 21.3125 19.2812 20.7681 19.2812 20.0938V17.6562C19.2812 16.9819 19.8256 16.4375 20.5 16.4375C21.1744 16.4375 21.7188 16.9819 21.7188 17.6562L21.7147 20.0938ZM20.5 22.1656C21.6213 22.1656 22.6531 21.3125 22.6531 20.0938H23.3438C23.3438 21.4831 22.2388 22.6287 20.9062 22.8237V24.1562H20.0938V22.8237C18.7613 22.6247 17.6562 21.4791 17.6562 20.0938H18.3469C18.3469 21.3125 19.3787 22.1656 20.5 22.1656Z" fill="#0066FF"/>
</svg>
</template>

<script>
export default {
    name: "SingleQuestion",
};
</script>

<style lang="scss" scoped>
</style>