<template>
    <div>
        <v-btn @click="handleOpenDialog" elevation="0" color="secondary">Get Started</v-btn>
        <v-dialog width="500" v-model="openEmailDialog">
            <v-card>
                <div class="modal-block">
                    <div v-if="!showEmailCustomization" class="text-h6 mt-4 mb-6">Share your voiceform via email</div>
                    <input-tag v-show="!uploadedCSV && !showEmailCustomization" v-model="emailList" add-tag-on-blur class="text-body-1" validate="email"
                        placeholder="Enter email addresses" :add-tag-on-keys="[13, 188, 9, 32]" :limit="30">
                    </input-tag>
                    <div v-show="showCSVResult" class="uploaded-file">
                        <v-icon color="primary">mdi-folder</v-icon>
                        <div class="ml-2 mr-2">{{ csvFileName }}</div>
                        <v-icon color="secondary" @click="deleteCSVFile">mdi-close</v-icon>
                    </div>
                    <div v-if="(!showUploadCSV || uploadedCSV) && !showEmailCustomization" class="mx-0 my-2 text-subtitle-2">
                        {{ contactCounts }} / {{ maxContact }} contacts
                    </div>

                    <v-expand-transition>
                        <div v-show="showCSVResult">
                            <p>We have successfully imported the following fields. These will be passed to your voiceform 😉</p>
                            <div class="metadata-fields">
                                <v-chip v-for="(metadata, idx) in metadataFields" :key="idx" class="mr-3 mb-4">
                                    {{ metadata }}
                                </v-chip>
                            </div>
                        </div>
                    </v-expand-transition>
                    <v-btn v-show="!showUploadCSV && !showEmailCustomization" @click="showEmailCustomization = true"
                        block class="mt-2 mb-0" elevation="0" color="primary">
                        Next
                    </v-btn>

                    <v-expand-transition>
                        <div v-show="showUploadCSV && !showEmailCustomization">
                            <div class="divider">
                                <v-divider class="divider-item"></v-divider>
                                <span class="divider-label">or</span>
                                <v-divider class="divider-item"></v-divider>
                            </div>
                            <v-btn class="mt-6 mb-6" block elevation="0" :disabled="!allowCSVUpload || loadingImportCSV" :loading="loadingImportCSV"
                                color="primary" @click="openFileInput">
                                Upload CSV email list
                            </v-btn>
                            <input type="file" accept="text/plain, text/csv" id="uploaded_list" name="uploaded_list"
                                class="d-none" @change="processFile($event)" />
                            <v-alert class="text-body-2 mb-0 info-description" type="info" text>
                                <div v-if="allowCSVUpload">
                                    Your contact list should include at least one column named <span>"email"</span>.
                                    Additionally, you can include other contact detail columns such
                                    as a <span>"name"</span>, <span>"phone number"</span>..etc. We will pass
                                    all metadata details to your voiceform results dashboard for tracking.
                                </div>
                                <div v-else>
                                    Looking to send your Voiceform via an email list? Checkout our
                                    <router-link :to="{name: 'dashboard.upgrade'}"><u>paid plans</u></router-link>
                                    to learn more.
                                </div>
                            </v-alert>
                        </div>
                    </v-expand-transition>

                    <!-- email customization components -->
                    <v-expand-transition>
                        <div v-show="showEmailCustomization">
                            <v-btn color="primary" class="pl-0 pr-0" text @click="showEmailCustomization = false">
                                <v-icon left>mdi-arrow-left</v-icon>
                                Edit email list
                            </v-btn>
                            <v-form v-model="emailCustomizationValidity" ref="emailForm">
                                <div class="text-h6 mb-6">Customize Email</div>
                                <v-text-field v-model="emailCustomization.subject" label="Subject" outlined required :counter="270"
                                    :rules="subjectTitleRule"></v-text-field>
                                <v-text-field v-model="emailCustomization.title" label="Title" outlined required :counter="270"
                                    :rules="subjectTitleRule"></v-text-field>
                                <v-textarea v-model="emailCustomization.body" outlined required name="input-7-4" label="Body" hide-details
                                    class="mb-4">
                                </v-textarea>
                                <v-text-field v-model="emailCustomization.buttonCTA" label="Call to action button text" outlined required
                                    :counter="25" :rules="buttonCTARule"></v-text-field>
                                <v-btn @click="sendEmails" :loading="loadingSendEmails" block class="mt-2 mb-0" elevation="0" color="primary"
                                    :disabled="!emailCustomizationValidity || loadingSendEmails">
                                    Send Emails
                                    <v-icon class="ml-2">mdi-send</v-icon>
                                </v-btn>
                            </v-form>
                        </div>
                    </v-expand-transition>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CustomInput from "@/components/elements/CustomInput.vue";
import InputTag from 'vue-input-tag';
import { shareSurveyEmailByList, shareSurveyEmailByCSV } from "../../../api/survey";
const emailValidationPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
    name: "ShareEmail",
    components: {
        CustomInput,
        InputTag
    },
    inject: ['survey'],
    data: () => ({
        openEmailDialog: false,
        csvFileName: "",
        csvTable: [],
        showUploadCSV: true,
        uploadedCSV: false,
        showEmailCustomization: false,
        emailList: [],
        contactCounts: 0,
        maxContact: 30,
        metadataFields: [],

        emailCustomization: {
            subject: "", title: "", body: "", buttonCTA: ""
        },
        defaultEmailCustomization: {
            subject: "You've been invited to a survey powered by Voiceform",
            title: "We'd love to hear from you.",
            body: "You have received an invitation to complete a Voiceform survey. To begin, select the \"Click to get started\" button below.",
            buttonCTA: "Click to get started"
        },
        subjectTitleRule: [
            v => !!v || "This field is required",
            v => (v && v.length <= 270) || "Must be 270 characters or less",
        ],
        buttonCTARule: [
            v => !!v || "This field is required",
            v => (v && v.length <= 25) || "Button Text must be 270 characters or less",
        ],
        emailCustomizationValidity: true,

        loadingSendEmails: false,
        loadingImportCSV: false
    }),
    watch: {
        emailList(val) {
            if (val.length > 0) {
                this.maxContact = 30;
                this.showUploadCSV = false;
            } else {
                this.showUploadCSV = true;
            }
            this.contactCounts = val.length;
        }
    },
    computed: {
        ...mapGetters({
            features: "auth/features",
        }),
        copyLink() {
            return process.env.VUE_APP_APP_URL + `/to/${this.survey.hash}`;
        },
        showCSVResult() {
            return this.uploadedCSV && !this.showEmailCustomization;
        },
        allowCSVUpload() {
            return this.features.share_email_by_csv;
        }
    },
    methods: {
        ...mapMutations(["showSnackBar"]),

        processFile(event) {
            const csvFile = event.target.files[0]; // we will only have take one file at the moment

            const reader = new FileReader();
            reader.addEventListener("load", this.uploadSuccess);
            reader.addEventListener("error", this.uploadError);

            reader.readAsText(csvFile, "UTF-8");

            this.loadingImportCSV = true;
            this.csvFileName = csvFile.name;
        },
        uploadSuccess(event) {
            this.loadingImportCSV = false;
            this.csvTable = event.target.result.split("\n");
            this.csvTable = this.csvTable.map(row => row.replaceAll(/(\r|\t)/g, "").split(","));

            const numColumns = this.csvTable[0].length;
            if (this.csvTable.find(row => row.length !== numColumns)) {
                this.showSnackBar({
                    color: "error",
                    timeout: 3000,
                    text: "Invalid CSV format: Number of columns are not the same for all rows."
                });
                return;
            }

            // turn all metadata tags to snake case
            this.csvTable[0] = this.csvTable[0].map(metadataTag => {
                return metadataTag &&
                    metadataTag.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
                        .map(x => x.toLowerCase())
                        .join("_");
            });

            const emailColumn = this.csvTable[0].findIndex(columnName => columnName === "email");
            if (emailColumn === -1) {
                this.showSnackBar({
                    color: "error",
                    timeout: 3000,
                    text: "'email' column is missing in the CSV file."
                });
                return;
            }

            // first row is the header row
            if (this.csvTable.length - 1 > 500) {
                this.showSnackBar({
                    color: "error",
                    timeout: 3000,
                    text: "Exceeded 500 contacts limit."
                });
                return;
            }

            const old_length = this.csvTable.length;
            this.csvTable = this.csvTable.filter(entry => entry[emailColumn] === "email" || emailValidationPattern.test(entry[emailColumn]));
            if (old_length > this.csvTable.length) {
                this.showSnackBar({
                    color: "warning",
                    timeout: 3000,
                    text: old_length - this.csvTable.length + " invalid email(s) have been removed"
                });
            }

            this.contactCounts = this.csvTable.length - 1;
            this.metadataFields = this.csvTable[0];

            // display result (asynchronous for smoother transition)
            setTimeout(() => {
                this.maxContact = 500;
                this.uploadedCSV = true;
                this.showUploadCSV = false;
            }, 0);
        },
        uploadError(event) {
            this.loadingImportCSV = false;
            console.error("Upload CSV error: ", event);
        },
        deleteCSVFile() {
            this.uploadedCSV = false;
            this.showUploadCSV = true;
            this.metadataFields = [];
            this.csvTable = [];
        },
        openFileInput() {
            document.querySelector("#uploaded_list").value = null; // remove old file from the input tag (if there are)
            document.querySelector("#uploaded_list").click();
        },
        async sendEmails() {
            try {
                const valid = this.$refs.emailForm.validate();
                if (!valid) {
                    return;
                }

                this.loadingSendEmails = true;
                let response = null;
                if (this.emailList.length > 0) {
                    // sending emails by the textfield
                    response = await shareSurveyEmailByList(this.emailList, this.copyLink, this.survey.hash, this.emailCustomization);
                } else {
                    // sending emails by the CSV imports
                    response = await shareSurveyEmailByCSV(this.csvTable, this.copyLink, this.survey.hash, this.emailCustomization)
                }
                if (response && response.status === 200) {
                    this.showSnackBar({
                        color: "success",
                        timeout: 3000,
                        text: "Emails sent successfully."
                    });
                    // close the email sendout dialog
                    this.openEmailDialog = false;
                }
                this.loadingSendEmails = false;
            } catch (err) {
                this.loadingSendEmails = false;
                if (err.response.status !== 200) {
                    this.showSnackBar({
                        color: "error",
                        timeout: 3000,
                        text: err.response.data.message || "Unable to send emails right now. Please try again later."
                    });
                }
            }
        },
        handleOpenDialog() {
            // reset all dialog data
            this.showUploadCSV = true;
            this.uploadedCSV = false;
            this.showEmailCustomization = false;
            this.emailCustomization = { ...this.defaultEmailCustomization };
            this.csvTable = [];
            this.emailList = [];
            this.metadataFields = [];

            this.openEmailDialog = true; // open the actual dialog
        },
    }
};
</script>

<style lang="scss" scoped>
.modal-block {
    padding: 2rem 4rem 4rem 4rem;

    .uploaded-file {
        padding: 12px;
        border-radius: 4px;
        background-color: rgba(0, 102, 255, 0.08);
        display: flex;
        justify-content: stretch;

        :last-child {
            margin-left: auto;
        }
    }

    .divider {
        margin-top: 3rem;
        align-items: center;
        display: flex;
    }

    .divider-label {
        color: rgb(125, 125, 125);
        padding: 0 1.5rem;
    }

    .info-description span {
        font-weight: 600; /* semi bold */
    }
}

.metadata-fields {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
}
</style>
