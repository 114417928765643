<template>
    <div>
        <h6 class="text-md-h6 mb-4 mt-6">
            <v-icon>mdi-account-multiple</v-icon>
            Recruit from our panel
        </h6>
        <v-card :outlined="true" :ripple="false" min-height="180" class="pa-4 mr-4 mb-4 panel">
            <div class="primary--text text-subtitle-1 text--darken1 mb-4">Buy high quality survey responses</div>
            <div class="text-body-2 mb-4 primary--text text--darken1">Easily find your ideal survey respondents for your market research studies with Voiceform Audience 🙌
            </div>
            <v-dialog v-model="dialog" scrollable max-width="1024px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed color="primary mt-4" v-bind="attrs" v-on="on">Learn more</v-btn>
                </template>
                    <v-card>
                        <v-card-title class="header">
                            <span class="text-md-h5">Buy high quality survey responses</span>
                            <v-btn icon @click="close()">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <div>
                            <div v-if="submitted">
                                <v-container
                                    class="flex items-center justify-center submitted-container d-flex flex-column align-center my-auto"
                                    style="padding-top: 50px;padding-bottom: 50px;">
                                    <div class="text-center" rounded outlined color="pa-4">
                                        <v-icon color="primary">mdi-check-circle-outline</v-icon>
                                        <h4 class="text-h5 mt-12">We have received your request</h4>
                                        <div class="text-body-1 mt-6">Our team has received your request for the panel. We
                                            will contact you shortly.</div>
                                        <v-btn class="mt-8" elevation='0' color="primary" @click="close()">Done</v-btn>
                                    </div>
                                </v-container>
                            </div>
                            <div v-else>
                                <v-row no-gutters>
                                    <v-col cols="6" style="height:600px; overflow-y: auto;" class="pa-6">
                                        <v-form ref="form" @submit.prevent="">
                                            <v-card :outlined="true" :ripple="false" class="mb-6" style="padding:15px">
                                                <div class="d-flex" style="justify-content: space-between;">
                                                    <h3 class="text-md-h6">Number of respondents</h3>
                                                    <span class="d-flex custom-input" style="width:125px">
                                                        <button class="btn" @click="respondents--">-</button>
                                                        <v-text-field outlined dense v-model="respondents"> </v-text-field>
                                                        <button class="btn" @click="respondents++">+</button>
                                                    </span>
                                                </div>
                                                <p>The number of respondents who will be selected from the total available
                                                    pool
                                                    based
                                                    on selected targeting criteria.</p>
                                            </v-card>
                                            <h3 class="mb-5 text-md-h6" style="margin-bottom: 5px;">Your Contact Info</h3>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-text-field :rules="[rules.required]" v-model="email" autocomplete="email" label="Email" outlined dense></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field placeholder="+16476790000" :rules="[rules.required]" v-model="phoneNumber" autocomplete="tel" label="Phone Number" outlined dense></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <h3 class="mb-5 text-md-h6">Targeting</h3>
                                            <v-divider></v-divider>
                                            <h6 class="text-subtitle-1">Gender</h6>
                                            <v-btn-toggle mandatory multiple v-model="gender" @change="handleGenderChange" tile color="primary accent-3" group outlined>
                                                <v-btn @click="gender=['Any']" value="Any">
                                                    Any
                                                </v-btn>
                                                <v-btn value="Men">
                                                    Men
                                                </v-btn>
                                                <v-btn value="Women">
                                                    Women
                                                </v-btn>
                                                <v-btn value="Non-binary">
                                                    Non-binary
                                                </v-btn>
                                            </v-btn-toggle>
                                            <h6 class="text-subtitle-1">Age</h6>
                                            <v-range-slider v-model="age" step="1" min="18" max="100"></v-range-slider>
                                            <div style="display:flex; justify-content: space-between; margin-top: -25px;">
                                                <span>{{ age[0] }}</span>
                                                <span>{{ age[1] }}</span>
                                            </div>
                                            <h6 class="text-subtitle-1">Language Requirements</h6>
                                            <v-select clearable dense hide-details="auto" outlined v-model="languages" multiple :items="languagesList"
                                                placeholder="Any"></v-select>
                                            <h6 class="text-subtitle-1">Countries</h6>
                                            <v-select clearable dense hide-details="auto" outlined v-model="countries" multiple :items="countriesList"
                                                placeholder="Any"></v-select>
                                            <h6 class="text-subtitle-1">Supported Devices</h6>
                                            <v-select clearable dense hide-details="auto" outlined v-model="devices" multiple :items="devicesList"
                                                placeholder="Any"></v-select>
                                            <h6 class="text-subtitle-1">Industries</h6>
                                            <v-select clearable dense hide-details="auto" outlined v-model="industries" multiple :items="industriesList"
                                                placeholder="Any"></v-select>
                                            <h6 class="text-subtitle-1">Additional Requirements</h6>
                                            <v-textarea v-model="requirements" outlined></v-textarea>
                                        </v-form>
                                    </v-col>
                                    <v-col style="background-color: var(--v-grey-lighten4);" color="grey--background" cols="6" class="pa-6">
                                        <v-card outlined>
                                            <v-card-title>
                                                Order Summary
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <div class="order-summary-item">
                                                    <span>
                                                        Respondents
                                                    </span>
                                                    <span>
                                                        {{ respondents }}
                                                    </span>
                                                </div>
                                                <div class="order-summary-item">
                                                    <span>
                                                        Gender
                                                    </span>
                                                    <span>
                                                        {{ genderSummary }}
                                                    </span>
                                                </div>
                                                <div class="order-summary-item">
                                                    <span>
                                                        Age
                                                    </span>
                                                    <span>
                                                        {{ ageSummary }}
                                                    </span>
                                                </div>
                                                <div class="order-summary-item">
                                                    <span>
                                                        Language Requirements
                                                    </span>
                                                    <span>
                                                        {{ languageSummary }}
                                                    </span>
                                                </div>
                                                <div class="order-summary-item">
                                                    <span>
                                                        Country
                                                    </span>
                                                    <span>
                                                        {{ countrySummary }}
                                                    </span>
                                                </div>
                                                <div class="order-summary-item">
                                                    <span>
                                                        Supported Devices
                                                    </span>
                                                    <span>
                                                        {{ deviceSummary }}
                                                    </span>
                                                </div>
                                                <div class="order-summary-item">
                                                    <span>
                                                        Industries
                                                    </span>
                                                    <span>
                                                        {{ industrySummary }}
                                                    </span>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <div v-if="!submitted">
                            <v-divider></v-divider>
                            <v-card-actions class="footer">
                                <v-btn elevation="0" large color="primary" @click="submit" :loading="loading" :disabled="loading">
                                    Request an Estimate
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import languages from '../../../assets/data/languages.json';
import devices from '../../../assets/data/devices.json';
import industries from '../../../assets/data/industries.json';
import countries from '../../../assets/data/countries.json';
export default {
    data() {
        const { surveyId } = this.$route.params;
        return {
            submitted: false,
            dialog: false,
            loading: false,
            surveyId,
            email: null,
            phoneNumber: null,
            requirements: null,
            age: [18, 45],
            gender: ['Any'],
            respondents: 250,
            languages: [],
            devices: [],
            industries: [],
            countries: [],
            languagesList: languages,
            devicesList: devices,
            industriesList: industries,
            countriesList: countries,
            rules: {
                required: value => !!value || 'Required'
            }, 
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        resetForm() {
            this.loading = false;
            this.submitted = false;
            this.email = this.user.email;
            this.phoneNumber = null;
            this.requirements = null;
            this.age = [18, 45];
            this.gender = ['Any'];
            this.respondents = 250;
            this.languages = [];
            this.devices = [];
            this.industries = [];
            this.countries = [];
            this.surveyId = this.$route.params.surveyId;
        },
        close() {
            this.dialog = false;
            this.resetForm();
        },
        async submit() {
            try {
                const valid = this.$refs.form.validate();
                if(!valid) {
                    return this.showSnackBar({
                        text: 'Some of the provided fields are invalid.', 
                        color: 'error', 
                        timeout: 2000
                    })
                }
                this.loading = true;
                await window.axios.post('/api/panel', {
                    surveyId: this.surveyId,
                    email: this.email,
                    phoneNumber: this.phoneNumber,
                    requirements: this.requirements,
                    age: this.age,
                    gender: this.gender,
                    respondents: this.respondents,
                    languages: this.languages,
                    devices: this.devices,
                    industries: this.industries,
                    countries: this.countries
                });
                this.loading = false;
                this.submitted = true;
            } catch (error) {
                this.loading = false;
                return this.showSnackBar({
                    text: 'Cannot submit this form. Please try again later.', 
                    color: 'error', 
                    timeout: 2000
                })
            }
            
        },
        handleGenderChange(value){
            if(value){
                if(value[value.length-1] === 'Any'){
                    return this.gender = ['Any']
                }

                if (value.length > 1 && value.includes('Any')) {
                    let valueCopy = [...value];
                    let index = valueCopy.indexOf('Any');
                    if (index !== -1) {
                        this.gender.splice(index, 1);
                    }
                }
            }
            
        },
        formatLabels(labels) {
            if (labels.length === 1) {
                return labels[0];
            } else {
                let result = '';
                for (let i = 0; i < labels.length - 1; i++) {
                result += labels[i] + ', ';
                }
                result += labels[labels.length - 1];
                return result;
            }
        },
    },
    mounted() {
        this.resetForm();
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
        ageSummary() {
            return `From ${this.age[0]} to ${this.age[1]}`
        },
        genderSummary(){
            return this.formatLabels(this.gender)
        },
        languageSummary() {
            if (this.languages.length === 0) {
                return 'Any'
            }

            if (this.languages.length === 1) {
                return this.languages[0];
            }

            return `${this.languages.length} languages`
        },
        countrySummary() {
            if (this.countries.length === 0) {
                return 'Any'
            }

            if (this.countries.length === 1) {
                return this.countries[0];
            }

            return `${this.countries.length} countries`
        },
        deviceSummary() {
            if (this.devices.length === 0) {
                return 'Any'
            }

            if (this.devices.length === 1) {
                return this.devices[0];
            }

            return `${this.devices.length} devices`
        },
        industrySummary() {
            if (this.industries.length === 0) {
                return 'Any'
            }

            if (this.industries.length === 1) {
                return this.industries[0];
            }

            return `${this.industries.length} industries`
        },
    },
}
</script>

<style scoped lang="scss" >
.submitted-container {
    color: #000000;
}

::v-deep .submitted-container .v-icon {
    font-size: 70px !important;
}

::v-deep .submitted-container .v-btn {
    width: 200px;
}

.panel {
    background-color: rgba(0, 102, 255, 0.08);
    border: 1px solid rgba(0, 102, 255, 0.5);
    border-radius: 4px;
}

.text-md-h6 {
    color: #000000;
}

.text-subtitle-1 {
    margin-top: 15px !important;
    margin-bottom: 6px !important;
    color: #000000;
}

.v-item-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
}

.v-item-group .v-btn {
    border-radius: 4px;
}

.order-summary-item,
.header {
    display: flex;
    justify-content: space-between;
}

.footer {
    display: flex;
    justify-content: flex-end;
}

.v-card__text {
    padding: 0px !important;
}

.order-summary-container {
    background: #F5F5F5;
}

.order-summary-item {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.px-26 {
    padding-left: 26px;
    padding-right: 26px;
}


.btn {
    color: #2341E0 !important;
    width: 40px;
    height: 40px;
    background: linear-gradient(0deg, rgba(0, 102, 255, 0.08), rgba(0, 102, 255, 0.08)), #FFFFFF;
    cursor: pointer;
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
}

::v-deep .custom-input {
    width: 125px;
    border: 1px solid #0066FF;
    height: 42px;
    border-radius: 2px;
    margin-bottom: 10px;
}

::v-deep .custom-input .v-input__slot {

    background: linear-gradient(0deg, rgba(0, 102, 255, 0.08), rgba(0, 102, 255, 0.08)), #FFFFFF !important;
    border: 0;
    border-radius: 0;

    fieldset {
        border: 0;
    }

    .v-text-field__slot input {
        color: #2341E0 !important;
        text-align: center;
    }
}
</style>