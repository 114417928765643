<template>
    <svg
        width="30"
        height="28"
        viewBox="0 0 30 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs>
            <linearGradient id="gradient">
                <stop offset="0%" stop-color="#0066FF" />
                <stop offset="100%" stop-color="#0066FF" />
            </linearGradient>
        </defs>
        <rect
            x="0.5"
            y="0.5"
            width="29"
            height="27"
            rx="5"
            fill="white"
            stroke="#ECECEF"
        ></rect>
        <rect
            x="6"
            y="6"
            width="18"
            height="16"
            rx="0.5"
            fill="url(#gradient)"
        ></rect>
    </svg>
</template>

<script>
export default {
    name: "Popup",
};
</script>

<style lang="scss" scoped>
</style>
