<template>
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs>
            <linearGradient id="gradient">
                <stop offset="0%" stop-color="#0066FF" />
                <stop offset="100%" stop-color="#0066FF" />
            </linearGradient>
        </defs>
        <rect
            x="1.5"
            y="2.5"
            width="29"
            height="27"
            rx="5"
            fill="white"
            stroke="#ECECEF"
        ></rect>
        <rect
            x="18"
            y="10"
            width="8"
            height="12"
            rx="1"
            fill="url(#gradient)"
        ></rect>
        <rect
            x="16"
            y="14"
            width="1"
            height="4"
            rx="0.5"
            fill="url(#gradient)"
        ></rect>
    </svg>
</template>

<script>
export default {
    name: "SideTab",
};
</script>

<style lang="scss" scoped>
</style>
