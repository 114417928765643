<template>
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs>
            <linearGradient id="gradient">
                <stop offset="0%" stop-color="#0066FF" />
                <stop offset="100%" stop-color="#0066FF" />
            </linearGradient>
        </defs>
        <rect
            x="1.5"
            y="2.5"
            width="29"
            height="27"
            rx="5"
            fill="white"
            stroke="#ECECEF"
        ></rect>
        <rect
            x="6"
            y="10"
            width="20"
            height="12"
            rx="1"
            fill="url(#gradient)"
        ></rect>
        <rect x="6" y="6" width="20" height="1" fill="url(#gradient)"></rect>
        <rect x="6" y="25" width="20" height="1" fill="url(#gradient)"></rect>
    </svg>
</template>

<script>
export default {
    name: "Standard",
};
</script>

<style lang="scss" scoped>
</style>
